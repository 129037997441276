<template>
  <v-container
    v-if="$can([titlePerms + '_list'])"
    id="crud"
    fluid
    tag="section"
  >
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Listado de {{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="showHeaders"
        :items="filteredData"
        :search="search"
        sort-by="secret"
        class="elevation-4 data__table"
      >
        <template v-slot:[`header.nombre`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:item.total="{ item }"> ${{ item.total }} </template>

        <template v-slot:top>
          <v-toolbar flat color="white" class="pr-2 mt-6">
            <v-row>
              <v-col sm="6" class="pt-20">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="'Busqueda de ' + title"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col sm="6" class="">
                <v-autocomplete
                  v-model="selectedHeaders"
                  :items="headers"
                  label="Columnas Visibles"
                  multiple
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text caption"
                      >(otras {{ selectedHeaders.length - 4 }}+)</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
      <v-row justify="space-between" class="ma-5">
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="
              $router.push(
                '/stock-estampillas/informe_stock_estampillas'
              )
            "
            >Atrás</v-btn
          >
        </v-col>
        <v-col v-if="$can([titlePerms + '_export'])" cols="auto">
          <v-btn color="secondary">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Exportar PDF
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

function title() {
  return "Items";
}

export default {
  data: (vm) => ({
    titlePerms: "almacen_control",
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
    search: "",
    valid: true,
    editedItem: {
      nombre: "",
      id: "",
    },
    defaultItem: {
      nombre: "",
      id: "",
    },

    dialog: false,
    readonly: true,
    readOnlySubtotal: true,
    headers: [
      { text: "Item", filterable: true, value: "nombre" },
      { text: "Descripción", filterable: true, value: "descripcion" },
      { text: "Desde", filterable: true, value: "desde" },
      { text: "Hasta", filterable: true, value: "hasta" },
      { text: "Cantidad", filterable: true, value: "cantidad" },
      { text: "Total", filterable: true, value: "total" },
    ],
    filters: {
      nombre: "",
      descripcion: "",
      desde: "",
      hasta: "",
      cantidad: "",
      total: "",
    },
    filterKey: [],
    selectedHeaders: [],
    desserts: [
      /*{
																																																								data: [*/
      {
        nombre: "AE100",
        descripcion: "AC - Estampilla 100$",
        desde: "X000018",
        hasta: "X000984",
        cantidad: "967",
        total: "96.700,00",
      },
      ,
      {
        nombre: "AE1M",
        descripcion: "AC - Estampilla 1000$",
        desde: "C100006",
        hasta: "C110000",
        cantidad: "9995",
        total: "9.995.000,00",
      },
      /*],
																																																							},*/
    ],
  }),

  mounted() {
    console.log("Componente " + this.baseUrl + " creado");
  },

  computed: {
    filteredData() {
      return this.$filteredData().data !== undefined
        ? this.$filteredData().data
        : this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },

  created() {
    console.log("almacen de ventas");
    this.selectedHeaders = this.headers;
  },

  methods: {
    filterByNombre(item) {
      return this.$filterBy(item, "nombre");
    },

    alert(title, msg) {
      Swal.alert(title, msg);
    },
  },
};
</script>

<style>
.carritoMsg__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 9rem;
  font-weight: bold;
  font-size: 0.85rem;
}
.carrito__div {
  overflow-y: auto;
  max-height: 41rem;
}
.data__table {
  overflow-y: auto;
  max-height: 50.75rem;
}
.div_total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.routerLink_total {
  background-color: #011f5b;
  color: white !important;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  text-decoration: none;
  border-radius: 0.5rem;
}
</style>
